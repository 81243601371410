import React, {useState,useEffect} from 'react';
import { Skeleton,Typography,Row,Col} from "antd";
import { PageHeader } from '@ant-design/pro-layout'



function DList(props) {


    useEffect(() => {

        return () => {

        }
    }, [])


    return (

        <PageHeader

        >
            <Row justify={'center'}>
                <Col>
                    <img src={'/logo_brand.svg'} width={300}/>
                </Col>
            </Row>
            <br/>

            <Row justify={'center'}> 
                <Col>
                    <Typography.Title level={4}>Professional Cinema Tracker</Typography.Title>
                </Col>
            </Row>
     
        </PageHeader>
    )
}

export default DList
