import React, {useState,useEffect,useContext,Suspense} from 'react'
import {FloatButton, Layout, Menu, Dropdown, Space, Typography, Button, Skeleton} from "antd"
import {LogoutOutlined, UserSwitchOutlined,MenuUnfoldOutlined,MenuFoldOutlined,ProjectOutlined,
    ContactsOutlined,UserOutlined,DollarOutlined,SettingOutlined,AppstoreOutlined,CreditCardOutlined,SafetyOutlined,PhoneOutlined} from "@ant-design/icons"
import {Link, useLocation} from "react-router-dom"
import { Outlet,useNavigate } from "react-router"
import {Context} from "../store"
import useFetch from "use-http"

import {FileDrawer} from "../components/Files";

const {Content, Footer, Sider, Header} = Layout

function MLayout(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [state, dispatch] = useContext(Context)
    const {get, response,loading} = useFetch()
    const { post, response:resPost, loading:loadPost} = useFetch()

    const [collapsed, setCollapsed] = useState(true)

    
    useEffect(() => {
        
        if(!state.auth.role) {
                get(`/api/auth/me`)
                    .then(d => {
                        if(response.ok){
                            dispatch({type:'SET_LOGIN',payload: d})
                        }
                    })
        }
        
        return () => {
            
        }
    }, [])
    

    const items = [
        { label: 'Contacts', key: 'entity', icon:<ContactsOutlined/> },
        { label: 'Petty Cash', key: 'cash',icon: <CreditCardOutlined/> },
        { label: 'Projects', key: 'project',icon: <ProjectOutlined/> },
        {
            label: 'Settings',
            key: 'setting',
            icon: <SettingOutlined/>,
            children: [
                { label: 'Categories', key: 'category',icon: <AppstoreOutlined/> },
                { label: 'Access', key: 'access',icon: <SafetyOutlined/> }],
        },
        { label: 'Profile', key: 'profile',icon: <UserOutlined/> },
        { label: 'Subscription', key: 'payment',icon: <DollarOutlined/> },
        { label: 'Support', key: 'support',icon: <PhoneOutlined/> },
    ];


    return (
        <Layout style={{minHeight: '100vh'}}>
            {/* <FloatButton.BackTop />*/}

            <Sider trigger={null} collapsible collapsed={collapsed} collapsedWidth="0">
          

                <img src={'/logo_brand.svg'} style={{margin:'10px',width:180}}/>
                <Menu theme="dark" selectedKeys={location.pathname.split('/')[1]} mode="inline" onSelect={() => {
                    setCollapsed(true)
                }} items={items} onClick={e => navigate(`/${e.key}`)}/>

            </Sider>

         
            <Layout>
             

                <Header style={{padding:'0 25px'}}>
                    <div style={{float: 'left'}}>
                        {collapsed ? <MenuUnfoldOutlined style={{color: 'white'}}
                                                         onClick={() => setCollapsed(!collapsed)}/> :
                            <MenuFoldOutlined style={{color: 'white'}} onClick={() => setCollapsed(!collapsed)}/>}
                    </div>
                            <Space  style={{ float:'right'}} >
                             <Typography.Text style={{color:'white'}}>{`${state.auth?.user?.data?.name} ${state.auth?.tenant?.data?.name ? ` in ${state.auth?.tenant?.data?.name}` : ''} `}</Typography.Text>

                                <Dropdown  menu={{ items:state.auth?.accounts?.map(o =>
                                        ({ label: `${o.tenant.data.name} as ${o.role}`, key: o.tenant.id }) ),
                                onClick:async ({ key }) => {
                                    let d = await post(`/api/auth/switch`, {tenant: Number(key)})
                                    if (resPost.ok) {
                                        dispatch({type: 'SET_LOGIN', payload: d})
                                        navigate('/')
                                    }
                                }
                                }} placement="bottom" >
                                    <Button shape="circle" loading={loadPost} icon={<UserSwitchOutlined />} />
                                </Dropdown>

                                <Button shape="circle" icon={<LogoutOutlined /> } loading={loadPost} onClick={async () => {
                                    await post(`/api/auth/logout`)
                                    if(resPost.ok){
                                        dispatch({type:'SET_LOGIN',payload: {}})
                                        navigate('/login')
                                    }
                                }}/>

                            </Space>
                       

                </Header>
                <FileDrawer/>
                <Content style={{background: '#fff', padding: '1rem'}}>

                    <Skeleton avatar loading={loading}>
                        <Suspense fallback={<Skeleton active />} >
                        <Outlet/>
                        </Suspense>
                    </Skeleton>

                </Content>
                <Footer style={{textAlign: 'center', padding: 0}}> ©2022 Transactgraph Sdn Bhd</Footer>
            </Layout>
           
        </Layout>
    )
}

export default MLayout


