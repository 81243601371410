import React, {useRef, useState, useEffect, useContext} from "react"
import {
    Button,
    Input,
    Space,
    Select,
    Table,
    Row,
    Col,
    Drawer,
    Upload,
    Card,
    Typography,
    Modal,
    Form,
    InputNumber,List,Avatar,Spin,Popconfirm,DatePicker
} from "antd"
import {FileTwoTone,UploadOutlined,DownloadOutlined,EyeOutlined,DeleteOutlined,EditOutlined} from "@ant-design/icons"
import {filesize} from "filesize";
import useFetch from "use-http";
import {MyDatePicker} from "./Date";

import {Context} from "../store";
import {CategorySelect} from "./Setting";
let RM = Intl.NumberFormat("en-my",{style:"currency","currency":"MYR"})


export const FileDrawer = function ({multiple=false}){
    const [state, dispatch] = useContext(Context)
    const [isAdd, setIsAdd] = useState(false)
    const [files,setFiles] = useState([])
    const [form] = Form.useForm()
    const { post, put, response:resUpdate, loading:loadUpdate } = useFetch()
    const { get,del, response, loading } = useFetch()
    const [visible,setVisible] = useState(false)
    let [urls,setUrl] = useState({})

    function sum(data){
        if(state.file.folder.type === 'bill' || state.file.folder.type === 'cash'){
            let v =  data.reduce((s,x) => s+=x.data.amount,0)
            dispatch({type:'SET_BILL',payload: {amount:v,id:state.file.folder.id}})
        }
        
    }


    async function load(url) {

        const d = await get(url || urls.list)
        if (response.ok) {

            setFiles(d)
        }

    }

    useEffect(() => {
        setIsAdd(false)
        setFiles([])
        let u = {}
        switch (state.file.folder.type){
            case 'entity':
                u.list = `/api/app/entities/${state.file.folder.id}/docs`
                u.single = `/api/app/docs`
                break
            case 'bill':
                u.list = `/api/app/bills/${state.file.folder.id}/docs`
                u.single = `/api/app/docs`
                break
            case 'project':
                u.list = `/api/app/projects/${state.file.folder.id}/docs`
                u.single = `/api/app/docs`
                break
            case 'cash':
                u.list = `/api/app/cash/bills/${state.file.folder.id}/docs`
                u.single = `/api/app/cash/docs`
                break
            case 'location':
                u.list = `/api/app/locations/${state.file.folder.id}/docs`
                u.single = `/api/app/docs`
                break
            case 'scene':
                u.list = `/api/app/scenes/${state.file.folder.id}/docs`
                u.single = `/api/app/docs`
                break
        }
        setUrl(u)
        if(state.file.visible){
            load(u.list)
        }
        

        return () => {

        }
    }, [state.file.visible])


    const onClose = () => {
        dispatch({type:'SET_FOLDER',payload: {folder:{},visible:false}})
    };


    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e?.fileList;
    };
    
    const truncate = (input) => input?.length > 20 ? `${input.substring(0, 20)}...` : input;

    return (
        <>
            <Drawer title={truncate(state.file.folder?.name)} zIndex={500} placement="right" onClose={onClose} open={state.file.visible}>
                <Row>
                    <Col span={24}>
                        {isAdd && <Button type={'primary'} onClick={() => {
                            setIsAdd(false)
                            load()
                        }} >Show Files</Button> }
                        {!isAdd && multiple && <Button type={'primary'} onClick={() => {
                            setIsAdd(true)
                        }} >Add Files</Button> }
                        { !multiple && <Space><Button type={'primary'} onClick={() => {
                            form.resetFields()
                            setVisible(true)
                        }} >Add File</Button>
                            <Button type={'link'} ><a href={`${urls.list}?zip=true`} download>Zip Files</a></Button>
                        </Space> }
                    </Col>

                </Row>
                <br/>
                {isAdd &&  <Row>
                    <Col span={24} >

                        <div style={{height:150}}> <Upload.Dragger multiple={true} showUploadList={{
                            showDownloadIcon: false,
                            showRemoveIcon: false,

                        }}   name={'upload'} method={ 'POST' } action={urls.single} data={() => {

                            return  new Promise( function(resolve, reject) {
                                let type = state.file.folder.type
                                if(type === 'cash') type = 'bill'
                                resolve({[type]:state.file.folder.id})
                            })

                        }}  >
                            <p className="ant-upload-drag-icon">
                                <UploadOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload
                            </p>
                        </Upload.Dragger></div>


                    </Col>

                </Row>}
                {!isAdd && <Row>
                    <Col span={24} >
                        <List loading={loading}
                              itemLayout="vertical"
                              dataSource={files}
                              renderItem={(item) => (
                                  <List.Item  actions={[
                                      <a href={`${urls.single}/${item.id}`} download><DownloadOutlined key={'download'}/></a>,
                                      <a href={`${urls.single}/${item.id}`} target={'_blank'}><EyeOutlined key={'view'}/></a>,
                                      <EditOutlined key={'edit'} onClick={() => {
                                          form.resetFields()
                                          form.setFieldsValue(item)
                                          setVisible(true)
                                      }}/>,


                                      <Popconfirm key={'delete'} title="Sure to delete?" onConfirm={ async () => {

                                          await del(`${urls.single}/${item.id}`)

                                          if(response.ok){
                                              let v = files.filter(o => o.id !== item.id)
                                              setFiles(v)
                                              sum(v)
                                          }



                                      }} >

                                          <DeleteOutlined />,
                                      </Popconfirm>
                                  ]}>
                                      <List.Item.Meta
                                          avatar={<FileTwoTone/>}
                                          title={item.data.name}
                                          description={filesize(item.file.size)}
                                      />
                                      {(state.file.folder.type === 'bill' || state.file.folder.type === 'cash') && <><Space>{RM.format(item.data.amount)} {item.data.date}</Space><br/></>}
                                      {item.data.description}
                                     
                                      
                                  </List.Item>
                              )}
                        />

                    </Col>
                </Row>}
            </Drawer>
            <Modal
                open={visible}
                title={`File`}
                zIndex={600}
                onOk={() => form
                    .validateFields()
                    .then(async values => {
                        let f = new FormData()
                        f.append('data',JSON.stringify(values.data))
                        if(values.expense)  f.append('expense',values.expense)
                        if(values?.upload?.length){
                            f.append('upload', values.upload[0].originFileObj)
                        }
                        if(values.id){
                            f.append('id', values.id)
                            let d = await put(urls.single,f)
                            if(resUpdate.ok){
                                setVisible(false)
                                let t = files.findIndex(o => o.id === d.id)
                                files[t] = d
                                let v = [...files]
                                sum(v)
                                setFiles(v)
                            }
                        }else{
                            let type = state.file.folder.type
                            if(type === 'cash') type = 'bill'
                            f.append(type, state.file.folder.id)
                            let d = await post(urls.single,f)
                            if(resUpdate.ok){
                                setVisible(false)
                                let v = [d,...files]
                                sum(v)
                                setFiles(v)
                            }
                        }
                       

                    })


                }
                onCancel={() => {
                    setVisible(false)
                }}
                confirmLoading={loadUpdate}
                destroyOnClose={true}

            >
                <Form  form={form}  labelCol={{ span: 5 }}
                       wrapperCol={{ span: 19 }} >
                    <Form.Item hidden
                               name={'id'}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                        {({getFieldValue}) => {
                            let id = getFieldValue(['id'])

                            let r = []
                            if(!id) r = [{required: true, message: 'File is required'}]
                            return  <Form.Item
                                label="File"
                                name={['upload']}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                rules={ r }
                            >
                                <Upload maxCount={1} beforeUpload={() => false} onChange={({file}) => {
                                    form.setFieldsValue({data:{name:file.name}})
                                }}>
                                    <Button icon={<UploadOutlined />}>Choose File</Button>
                                </Upload>
                            </Form.Item>
                        }}
                    </Form.Item>
                    <Form.Item
                        label="Name"
                        name={['data','name']}
                        rules={ [{required: true, message: 'Name is required'}] }
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name={['data','description']}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                   

                    {(state.file.folder.type === 'bill' || state.file.folder.type === 'cash') && 
                        <>
                            <Form.Item
                                label="Bill Date"
                                name={['data','date']}
                                rules={ [{required: true, message: 'Date is required'}] }
                            >
                                <MyDatePicker/>
                            </Form.Item>
                            <Form.Item
                                label="Amount"
                                name={['data','amount']}
                                rules={ [{required: true, message: 'Amount is required'}] }
                            >
                                <InputNumber/>
                            </Form.Item>
                            <Form.Item
                                label="Expenses"
                                name={['expense']}
                                rules={[]}

                            >
                                <CategorySelect filter={'Expense'} plus={state.file.folder.type === 'bill'}/>
                            </Form.Item>
                        </>
                    }
                   

                </Form>
            </Modal>
        </>
    )
}

export const FileShow = function ({folder,button=false}){
    const [state, dispatch] = useContext(Context)
    
    let f = () => {
        dispatch({type:'SET_FOLDER',payload: {folder,visible:true}})
    }
    return button ? <Button onClick={f}>Files</Button> : <FileTwoTone  onClick={f} />
}

