import React, {createContext,useReducer} from 'react'

const reducer = (state,action) => {
    switch (action.type) {
        case 'SET_LOGIN':
            return {...state,auth:action.payload}
        case 'SET_FOLDER':
            return {...state,file:action.payload}
        case 'PROJECT':
            return {...state,project:action.payload}
        case 'CATEGORY':
            return {...state,category:action.payload}
        case 'ENTITY':
            return {...state,entity:action.payload}
        case 'LOCATION':
            return {...state,location:action.payload}
        case 'CHARACTER':
            return {...state,character:action.payload}
        case 'SET_BILL':
            return {...state,bill:action.payload}
        default:
            return state
    }
}

let initialState = {
    auth: {accounts:[]},
    file:{visible:false,folder:{}},
    project:{visible:false,record:{}},
    category:{visible:false,record:{}},
    entity:{visible:false,record:{}},
    location:{visible:false,record:{}},
    character:{visible:false,record:{}},
    bill:{}
}

const Store = ({children}) => {
    const [state,dispatch] = useReducer(reducer,initialState)

    return (
        <Context.Provider value={[state,dispatch]}>
            {children}
        </Context.Provider>
    )
}

export const Context = createContext(initialState)

export default Store
