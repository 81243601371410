import React, {useRef, useState} from "react"
import {DatePicker, Select,Button, Input, Space, Table} from "antd"
import dayjs from "dayjs";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';


export const MyDatePicker = ({value, onChange,...props}) => {

    return (
        <DatePicker value={value ? dayjs(value):null} onChange={o => onChange(o?.format('YYYY-MM-DD')) } {...props}/>
    )

}

export const Editor = ({value, onChange}) => {
    return (
        <ReactQuill theme="snow" value={value} onChange={onChange} />
    )

}

export const MultiSelect = ({value, onChange,loading,source,...props}) => {
    return (
        <Select mode="multiple" value={value?.map(o => o.id)} loading={loading} allowClear={true} onChange={e => onChange(source.filter((el) => e.includes(el.id)))}   {...props}>
            {source.map((o) =>
                <Select.Option key={o.id} value={o.id} >{o.data.name}</Select.Option>
            )}
        </Select>
    )

}

export const MyTable = ({columns,...props}) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState([]);
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters,confirm) => {
        clearFilters();
        setSearchText('');
        setSearchedColumn([]);
        confirm()
       
    };


    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            handleReset(clearFilters,confirm)
                        }}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>

                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>{
            let obj = record
            let index = 0
            while (index < dataIndex.length) {
                obj = obj[dataIndex[index++]]
                if(obj == null) {
                    obj = ''
                    break
                }
            }
            return obj.toString().toLowerCase().includes(value.toLowerCase())
           },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>{
            return  searchedColumn.toString() === dataIndex.toString() ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            )}
    });
    

    let mycolumns = []
    columns.forEach((o) => {
        if(o.search){
            mycolumns.push({...o, ...getColumnSearchProps(o.dataIndex)})
        }else{
            mycolumns.push(o)
        }
    })
    return <Table  {...props} columns={mycolumns} />
}


/*
//Composition
const _CustomDatePicker = (AntDatePicker) => function Component({value, onChange, ...props}) {
    useEffect(() => {

        return () => {
        }
    }, [])

    return (
        <DatePicker value={value ? dayjs(value) : null} onChange={o => onChange(o.format('YYYY-MM-DD'))} {...props}/>
    )
}

export const CustomDatePicker = _CustomDatePicker(DatePicker)
*/
