import React, {useRef, useState, useEffect, useContext,useMemo} from "react"
import {Button, Select, Modal, Form, Input, Spin, Empty, Divider, Checkbox, InputNumber} from "antd"
import {EditOutlined, PlusOutlined, ReloadOutlined} from "@ant-design/icons"
import { BehaviorSubject } from "rxjs"
import { useSubscription } from 'observable-hooks'

import useFetch from "use-http";
import {Context} from "../store";


export const EditCategory = ({record}) => {
    const [, dispatch] = useContext(Context)

    return <EditOutlined onClick={() => {
        dispatch({type:'CATEGORY',payload: {record,visible:true}})
    }}/> 
}

export const NewCategory = () => {
    const [, dispatch] = useContext(Context)

    return  <Button type={'primary'} onClick={() => {
        dispatch({type:'CATEGORY',payload: {record:{},visible:true}})
    }}>Add New</Button>
}

let listLoading = false

let subject = new BehaviorSubject(null)
const useSharedList = () => {
    const [list, setList] = useState([])
    const { get, response, loading } = useFetch()


    useSubscription(subject, data => {
        if(!data){
            loadList()
        }else{
            setList(data)
        }
    })

    function addList(data) {
        subject.next([data,...list]);
    }

    function updateList(data) {
        let v = list.findIndex(o => o.id === data.id)
        list[v] = data
        subject.next([...list])
    }

    async function loadList() {
        if(listLoading) return
        listLoading = true
        const d = await get(`/api/app/categories`)
        if (response.ok) {
            listLoading = false
            subject.next(d);
        }
    }

    return { list, addList, updateList,loadList, loading };
};

const CategoryForm = ({form,filter}) => {
    
    return <Form  form={form}  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 20 }} >
        <Form.Item hidden
                   name={'id'}
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Name"
            name={['data','name']}
            rules={ [{required: true, message: 'Name is required'}] }
        >
            <Input/>
        </Form.Item>
 
        <Form.Item shouldUpdate noStyle>
            {({getFieldValue}) => {
                let id = getFieldValue(['id'])
                if(!id && !filter){
                    return   <Form.Item
                        label="Type"
                        name={['data','type']}
                        rules={[{required: true, message: 'Type is required'}]}

                    >
                        <Select >
                            {['Department','Expense'].map((o) =>
                                <Select.Option key={o}>{o}</Select.Option>
                            )}

                        </Select>
                    </Form.Item>
                }

            }}
        </Form.Item>


    </Form>
}

export const  CategoryModal =  ({done}) => {
    const [state, dispatch] = useContext(Context)
    const [form] = Form.useForm()
    const { post,put, response, loading } = useFetch()

    useEffect(() => {

        if(state.category.visible){
            form.resetFields()
            if(state.category.record.id){
                form.setFieldsValue(state.category.record)
            }
        }

        return () => {

        }
    }, [state.category.visible])

    return <Modal
        open={state.category.visible}
        title="Category"
        onOk={()=> {
            form
                .validateFields()
                .then(async values => {
                  
                    if(values.id){
                        let d = await put(`/api/app/categories`,values)
                        if(response.ok) {
                            dispatch({type:'CATEGORY',payload: {record:{},visible:false}})
                            if(typeof done === 'function') done(d)
                        }
                    }else{
                        if( state.category.filter) values.data.type = state.category.filter
                        let d = await post('/api/app/categories',values)
                        if(response.ok) {
                            dispatch({type:'CATEGORY',payload: {record:{},visible:false}})
                            if(typeof done === 'function') done(d)

                        }
                    }

                })


        }}
        onCancel={() => {
            dispatch({type:'CATEGORY',payload: {record:{},visible:false}})
        }}
        confirmLoading={loading}
        destroyOnClose={true}

    >
        <CategoryForm form={form} />
        
    </Modal>
}

export const CategorySelect = ({onChange,value,filter,plus=false,...props}) => {
    const [form] = Form.useForm()
    const [visible,setVisible] = useState(false)
    const { post, response, loading } = useFetch()
    const [open,setOpen] = useState(false)

    const {loadList,list,loading:listLoading,addList} = useSharedList()

    
    return <><Select
        value={value}
        loading={listLoading}
        onChange={v => {
            onChange(v)
        }}
        open={open}
        onDropdownVisibleChange={(open) => setOpen(open)}
        showSearch
        filterOption={(input, option) =>{
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
        onSearch={async (val) => {
            if(val.length === 3){


            }
        }}
        notFoundContent={loading ? <Spin size="small" /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        dropdownRender={menu => (
            <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>

                    {plus &&  <a
                        style={{ flex: 'none',  display: 'block', cursor: 'pointer' }}
                        onClick={()=>{
                            setOpen(false)
                            form.resetFields()
                            setVisible(true)
                        }}
                    >
                        <PlusOutlined /> Add New
                    </a>}
                    <a
                        style={{ flex: 'none',  display: 'block', cursor: 'pointer',marginLeft:10 }}
                        onClick={()=>{
                            loadList()
                        }}
                    >
                        <ReloadOutlined /> Reload
                    </a>
                </div>
            </div>
        )}
    >
        {list.filter(o => {
            if(filter){
                return o.data.type === filter
            }else{
                return o
            }
            return o
        }).sort((a, b) => a.data.name.localeCompare(b.data.name)).map((o) =>
            <Select.Option key={o.id} value={o.id}>{o.data.name}</Select.Option>
        )}
    </Select>
        <Modal
            open={visible}
            title="Category"
            onOk={()=> {
                form
                    .validateFields()
                    .then(async values => {

                            if(filter) values.data.type = filter
                         
                            let d = await post('/api/app/categories',values)
                            if(response.ok) {
                                addList(d)
                                onChange(d.id)
                                setVisible(false)
                            }
                        

                    })


            }}
            onCancel={() => {
                setVisible(false)
            }}
            confirmLoading={loading}
            destroyOnClose={true}

        >
            <CategoryForm form={form} filter={filter} />
        </Modal>
        </>
}